@import '../../shared/styles/config';

.profileBackground {
  padding: $grid-gutter-width;
  background: $pink-background;
}

.iconContainer {
  background-image: $gradient;
  border-radius: 50%;
  margin-bottom: 8px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  position: relative;
  margin-bottom: $grid-gutter-width;
  font-weight: bold;
  overflow: hidden;
  width: 120px;
  height: 120px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconSettings {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5 * $font-size;
  color: white;
}

.profileImage {
  width: 100%;
  height: auto;
}

.initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}

.removePhoto {
  font-size: 0.875 * $font-size !important;
  color: $primary !important;
  margin-top: 0.25 * $grid-gutter-width;
}

.textSizeContainer {
  padding: $grid-gutter-width;

  :global {
    .rc-slider-track {
      margin-top: -1px;
    }
    .rc-slider-step {
      top: 50%;
    }
    .rc-slider-dot {
      height: 30px;
      width: 4px;
      margin: 0;
      top: 0;
      bottom: 0;
      transform: translateY(-50%);
      background: $grey-slider;
      border-radius: 0;
      border: none;
    }
    .rc-slider-handle {
      padding: 0.5 * $grid-gutter-width;
      background: $primary;
      border: none;
      margin: 0;
      margin-left: 2px;
      transform: translate(-50%, -50%) !important;
    }

    .rc-slider-handle:focus {
      border: 3px solid lightblue;
    }

    .rc-slider-mark {
      display: none;
    }

    .rc-slider-dot-active {
      border: none;
    }
    .rc-slider {
      margin: 0 16px;
    }
  }
}

.sliderHandle {
  padding: 0.5 * $grid-gutter-width;
  background: $primary;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sliderMark {
  border: 1px solid green;
  height: 30px;
  width: 0;
}

.textSizeSliderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallSample {
  font-size: 12.8px;
}

.largeSample {
  font-size: 32px;
}
