@import '../../shared/styles/config';

.container {
  padding-top: 50px;
  padding-bottom: 50px;
  @include fadeIn;
}

.title {
  margin-bottom: 20px;
}

.description {
  margin-bottom: 20px;
}
