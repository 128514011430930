@import '../../shared/styles/config';

.programCard {
  display: flex;
  background-color: $pink;
  margin-bottom: $grid-gutter-width;

  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.programLink {
  display: block;
  flex: 1;
  &:hover {
    text-decoration: none;
  }

  p {
    color: $grey-dark;
  }
}

.programContent {
  flex: 1;
}

.programTitle {
  text-align: left;
}

.icon {
  max-width: 150px;
  margin-bottom: 30px;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-right: 39px;
    margin-bottom: 0;
  }
}

.programContainer {
  padding: 0.75 * $grid-gutter-width;
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(sm) {
    padding: 1.5 * $grid-gutter-width;
    flex-direction: row;
    align-items: flex-start;
  }
}

.complete {
  display: flex;
  width: 100%;
  background: $purple-light !important;
  padding: 1.5 * $grid-gutter-width !important;
  padding-right: $grid-gutter-width !important;
  align-items: center;

  @include media-breakpoint-up(lg) {
    width: 40%;
  }

  img {
    margin-right: $grid-gutter-width;
    max-width: 50%;
  }
}

.completeTitle {
  color: $purple;
  font-weight: 900;
}

.settingsToggle {
  color: $primary !important;
  font-size: $font-size * 0.9 !important;
  svg {
    transition: transform 0.1s ease-in;
    margin-left: 0.25 * $grid-gutter-width;
  }
}

.open {
  transform: rotate(180deg) !important;
  transition: transform 0.1s ease-out;
}

.translations {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $grey-dark;
  border-top: 1px solid $grey-dark;
  padding-top: 20px;
}
