@import '../../shared/styles/config';

.statsPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.printButton {
  margin-bottom: 0.5rem !important;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}

.statsTable {
  border-left: 1px solid $table-border;
  border-right: 1px solid $table-border;
  border-bottom: 1px solid $table-border;
  margin-bottom: 0 !important;
  :global(th) {
    font-weight: normal;
    color: $grey-dark;
  }
  :global(tr) {
    color: $grey-dark;
  }
  :global(span) {
    color: $primary;
    font-weight: $font-weight-bold;
  }
}

.fixedWidthTable {
  :global(th) {
    min-width: 250px;
  }
}

.statsTableCell {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.statsTableCaption {
  line-height: 1em;
  color: lighten($grey-dark, 10%);
  font-size: 0.9rem;
}

.progressBar {
  border: 1px solid $grey-dark;
  width: 50%;
  height: 20px;
  margin-right: $grid-gutter-width * 2;
  border-radius: $border-radius;
  position: relative;
}

.progressBarFill {
  background-color: $primary;
  height: 100%;
}

.snippetTimeSpent {
  padding: $grid-gutter-width;
  background-color: transparentize($red, 0.9);
  border-radius: $border-radius-lg;
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.snippetTimeSpentTitle {
  line-height: 1em;
  font-size: $font-size * 2.5;
  font-weight: $font-weight-bold;
  color: $red;
  margin-right: $grid-gutter-width;
  margin-bottom: 0;
  @include media-breakpoint-down(sm) {
    margin-bottom: $grid-gutter-width / 4;
  }
}
.snippetTimeSpentSubtitle {
  line-height: 1em;
  font-size: $font-size * 1.15;
  font-weight: $font-weight-bolder;
  color: $grey-dark;
  margin-bottom: 0;
  max-width: 250px;
  margin-bottom: 0;
  @include media-breakpoint-down(sm) {
    margin-bottom: $grid-gutter-width / 4;
  }
}
.snippetTimeSpentCaption {
  line-height: 1em;
  color: lighten($grey-dark, 10%);
  font-size: $font-size * 0.95;
  margin-bottom: 0;
  margin-left: $grid-gutter-width;
  @include media-breakpoint-down(sm) {
    margin-bottom: $grid-gutter-width / 4;
    margin-left: 0;
  }
}

@media print {
  .snippetTimeSpent {
    page-break-after: always;
  }

  .statsDivider {
    display: none;
  }
}
