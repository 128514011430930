@import '../../shared/styles/config';

.icon {
  height: 50%;
  margin-right: 8px;
  position: relative;
  margin-bottom: 8px;
  width: 50%;

  @include media-breakpoint-up(md) {
    height: 100px;
    width: 100px;
    margin-bottom: 30px;
  }

  img {
    width: 50%;
    top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%);
  }
}

.title {
  margin-bottom: 7px; // h3 padding+margin to match current site
  text-align: center;
}

.programPreview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;

  @include media-breakpoint-up(md) {
    width: 33%;
  }
}

.preview {
  text-align: center;
}
