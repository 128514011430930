@import '../../shared/styles/config';

.certificate {
  display: flex;
  width: 100%;
  background: $purple-light !important;
  padding: 1.5 * $grid-gutter-width !important;
  padding-right: $grid-gutter-width !important;
  align-items: center;

  margin-bottom: $grid-gutter-width;

  &.card {
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      width: 40%;
    }
  }

  img {
    margin-right: $grid-gutter-width;
    max-width: 50%;
  }
}

.certificateTitle {
  color: $purple;
  font-weight: 900;
}
