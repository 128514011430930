$font-size-nominal-default: 16px;
$font-size-base-target: 18px;

$font-size-base: 1rem * $font-size-base-target / $font-size-nominal-default;
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-Light.ttf') format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-Medium.ttf') format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-Black.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-LightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-MediumItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Rubik';
  src: url('../../fonts/rubik/Rubik-BlackItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'museo-slab';
  src: url('../../fonts/museo-slab/Museo_Slab_500.otf') format('opentype');
}
$text-color: #5c5c5c;
$primary: #eb2756;
$font-family-sans-serif: 'Rubik', Sans-serif;
$font-family-serif: 'museo-slab', serif;
$font-family-base: $font-family-sans-serif !default;
