body {
  color: $text-color;
  font-size: $font-size;
  font-family: $font-family-sans-serif;
  line-height: 1.5;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  padding-top: 45px;
}

input {
  border: 1px solid $input-border;
  transition: border-color 300ms;
  outline: none;
  &:focus {
    border-color: $grey-form;
  }
}

ul.checklist {
  list-style: none;

  li {
    position: relative;

    &:before {
      content: url('../icons/check.svg');
      position: absolute;
      width: 12px;
      color: $text-color;
      left: -20px;
    }
  }
}
