@import '../../shared/styles/config';

.modalDialog {
  :global(.modal-content) {
    border-radius: 0px;
    border: none !important;
    box-shadow: 3px 0px 13px -1px rgba(0, 0, 0, 0.25);
  }
  :global(.modal-header) {
    border-bottom: none !important;
  }
  :global(.modal-footer) {
    border-top: none !important;
  }
  :global(.modal-header) {
    :global(button) {
      opacity: 1;
      &:hover,
      &:active,
      :focus,
      :focus-visible {
        opacity: 1 !important;
        :global(span) {
          font-weight: $font-weight-base;
          color: $black !important;
        }
      }
      :global(span) {
        font-weight: $font-weight-base;
        color: $grey-darker !important;
      }
    }
  }
}

.modalBackdrop {
  background-color: $white !important;
  opacity: 0.9 !important;
}
