@import '../../shared/styles/config';

.previewCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1.25 * $grid-gutter-width;
  margin-bottom: 1.25 * $grid-gutter-width;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid $grey-dark;
  }
  @include media-breakpoint-up(sm) {
    &:not(:last-child) {
      border-bottom: 1px solid $text-color;
    }
  }
}

.excerpt {
  margin-bottom: 20px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon {
  color: $primary;
  width: 20px;
}

.dottedBorder {
  display: none;
  @include media-breakpoint-down(sm) {
    display: block;
    background-image: linear-gradient(to right, $dotted-border 20%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 5px 1px;
    background-repeat: repeat-x;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 1px;
  }
}
