@import '../../shared/styles/config';

.headerButton.headerButton {
  font-weight: 400;
  font-size: 12px;
  &:not(:last-child) {
    margin-right: 0.5 * $grid-gutter-width;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    padding: 12px 15px !important;

    &:hover,
    &:focus {
      box-shadow: none;
    }
  }
  &.lead {
    font-weight: 600 !important;
    font-size: 1rem;
  }
}

.headerDropdown.headerDropdown {
  font-weight: 600 !important;
  &:after {
    display: none;
  }
  &:hover + :global(.dropdown-menu) {
    display: block;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    text-align: left;
  }
}

.navContainer {
  color: $white;
  padding-top: 0.5 * $grid-gutter-width;
  padding-bottom: 0.5 * $grid-gutter-width;
}

.navBackground {
  background-image: $gradient;
}

.logo {
  max-width: 45px;
}

.mobileNav.mobileNav {
  width: 100vw;
  max-width: 350px;
  position: fixed;
  right: 100%;
  top: 0;
  transition: transform 0.25s ease-in;
  height: 100vh;
  background: $mobile-nav;
  padding: 15px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a,
  button {
    color: $grey-dark !important;
    margin: 0.25rem 0;
  }
}

.openNav {
  transform: translateX(100%);
  transition: transform 0.25s ease-out;
}

.navToggle.navToggle {
  padding: 5px;
  background: none;
  display: none;

  @include media-breakpoint-down(md) {
    margin-right: 2rem;
    display: block;
  }
}

.closeNav.closeNav {
  background: none;
  margin-left: auto !important;

  &:hover {
    background: none;
  }
}

.mobileNavShadow {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background-color: #000000;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.25s linear;
}

.showShadow {
  opacity: 0.25;
  z-index: 1;
  transition: opacity 0.25s linear;
}

.dropdownMenu.dropdownMenu {
  border-radius: 0;
  padding: 0;
  border: none;
  min-width: 0;

  @include media-breakpoint-down(md) {
    position: relative !important;
    transform: none !important;
    width: 100%;
  }
}

.dropdownLink {
  padding: 13px 20px;
  transition: 0.4s;
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  display: block;
  white-space: nowrap;
  color: $black;

  &:hover,
  &:focus {
    background-color: $dropdown-item-hover !important;
    color: white;
    transition: 0.4s;
    text-decoration: none;
  }

  @include media-breakpoint-down(md) {
    position: relative;
    background-color: $grey !important;
    margin: 0 !important;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 100%;
      background-color: $grey !important;
      top: 0;
    }

    &:before {
      left: 100%;
    }

    &:after {
      right: 100%;
    }
    &:hover,
    &:focus {
      background-color: none !important;
      color: inherit;
    }
  }
}

.dropdownToggle.dropdownToggle {
  color: white;
  font-size: 1rem;
  font-weight: 600;

  &:hover,
  &:focus {
    color: white;
  }

  @include media-breakpoint-down(md) {
    padding: 0 !important;
  }
}

.languageSwitcher {
  .headerButton {
    font-weight: 400;
    @include media-breakpoint-up(md) {
      font-size: 12px;
    }
  }

  .dropdownLink {
    width: 100%;
    text-align: left;
    padding: 8px 15px;
    @include media-breakpoint-up(md) {
      font-size: 12px;
      background-color: $pink !important;
      color: $primary;
      &:hover {
        background-color: $primary !important;
        color: $white;
      }
    }
  }
  svg {
    margin-left: 4px;
  }
  button {
    border: none;
  }
}

.dropdownMenu {
  margin-top: 0px !important;
}

@media print {
  .navContainer {
    display: none;
  }
}
