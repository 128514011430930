@import '../../shared/styles/config';

.barChartWrapper {
  position: relative;
  margin-top: $grid-gutter-width;
  display: flex;
  flex-direction: column;
  color: $grey-dark;
}

.barChart {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  padding-bottom: $grid-gutter-width * 4;
  justify-content: space-between;
}

.barGroup {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 400px;
}

.bar {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 100%;
  border: 1px solid $grey-dark;
  &:global(.red) {
    border-left: none;
  }
}

.barFill {
  width: 55px;
  text-align: center;
  color: $white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  :global(p) {
    padding-top: $grid-gutter-width / 4;
  }
  &:global(.orange) {
    background-color: $orange;
  }
  &:global(.red) {
    background-color: $red;
  }
  @include media-breakpoint-down(lg) {
    font-size: $font-size-base * 0.65;
    width: 35px;
  }
  @include media-breakpoint-down(sm) {
    width: 20px;
    color: transparent;
  }
}

.barLabel {
  line-height: 1em;
  color: $grey-dark;
  text-align: center;
  margin-bottom: 0;
  position: absolute;
  bottom: -15px;
  transform: translateY(100%);
  @include media-breakpoint-down(md) {
    font-size: $font-size-sm;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.legend {
  display: flex;
  align-self: flex-end;
  margin-bottom: $grid-gutter-width;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: $grid-gutter-width;
  :global(p) {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    margin: 0;
  }
}

.legendItemCircle {
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 11px;
  margin-right: $grid-gutter-width / 4;
  &:global(.orange) {
    background-color: $orange;
  }
  &:global(.red) {
    background-color: $red;
  }
}

.scale {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  padding-left: 70px;
  :global(p) {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(md) {
    position: relative;
  }
  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }
}

.scaleLabel {
  color: lighten($grey-dark, 20%);
  position: absolute;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  text-align: center;
  transform: rotate(180deg);
  left: 0;
  top: 0;
  bottom: $grid-gutter-width * 4;
  margin: 0;
  @include media-breakpoint-down(md) {
    bottom: 0;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

@media print {
  .barChartWrapper {
    page-break-after: always;
  }
}
