@import '../../shared/styles/config';

.dashboard {
  padding-top: $grid-gutter-width;
  main {
    padding-top: 0px;
    width: 100%;
    flex-grow: 1;
  }
}

.dashboardCol {
  overflow-x: hidden;
}
