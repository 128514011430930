@import '../../shared/styles/config';

.dashboardCard {
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width / 2;
  background-color: transparentize($red, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-radius: $border-radius-lg;
  height: 100%;
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.title {
  line-height: 1em;
  font-size: $font-size * 2.5;
  font-weight: $font-weight-bold;
  color: $red;
}

.subtitle {
  line-height: 1em;
  font-size: $font-size * 1.15;
  font-weight: $font-weight-bolder;
  margin-bottom: $grid-gutter-width / 2;
  color: $grey-dark;
}

.caption {
  line-height: 1em;
  color: lighten($grey-dark, 10%);
  font-size: $font-size * 0.95;
  margin-bottom: 0;
}
