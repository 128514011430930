@import '../../shared/styles/config';

.option,
.option:disabled {
  background: inherit;
  border-left: $grey-dark dotted !important;
  border-radius: 4px !important;
  color: $text-color !important;
  display: block !important;
  padding: $grid-gutter-width * 0.5 $grid-gutter-width !important;
  text-align: left;
  transition: all 0.3s ease !important;
  margin-bottom: $grid-gutter-width * 0.75 !important;
  margin-top: $grid-gutter-width * 0.75 !important;
  width: 100%;

  @include media-breakpoint-up(lg) {
    &:hover,
    &:focus,
    &:active {
      background: transparentize($grey-dark, 0.9) !important;
      border-left: $black dotted !important;
      color: $text-color;
      cursor: pointer;
      transform: scale(1.1);
      transition: all 0.3s ease !important;
    }
  }

  &:disabled {
    &:hover,
    &:focus,
    &:active {
      background: none !important;
      cursor: initial;
    }
  }
}

.disabled {
  &:hover,
  &:focus,
  &:active {
    transform: none !important;
    background: inherit;
    cursor: initial;
  }
}

.details {
  line-height: 1.5em;
}
