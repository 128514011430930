@import '../../shared/styles/config';

.learnerCardContainer {
  > :global(*) {
    margin-bottom: $grid-gutter-width;
  }
}

.learnersTable {
  border-color: $gray-200;
  border-style: solid;
  border-width: 2px;
  :global(tbody > tr:nth-child(odd)) {
    background-color: $gray-100;
  }
  :global(td:nth-child(3)),
  :global(th:nth-child(3)) {
    text-align: center;
    vertical-align: middle;
  }
  :global(button) {
    width: 100%;
    height: 100%;
    border: none !important;
    background-color: transparent !important;
  }
}

.errorMessage {
  color: $red-alt;
}

.successMessage {
  color: $green;
}

.archivedViewToggleWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: $grid-gutter-width / 2;
  :global(label) {
    font-weight: $font-weight-bold;
    margin-left: $grid-gutter-width / 2;
    margin-bottom: 0px;
  }
}

.enrollCtasContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  > :global(button) {
    margin-bottom: $grid-gutter-width;
  }
}

.requestSeats {
  max-width: 180px;
  align-items: flex-end !important;
  :global(svg) {
    font-size: $font-size-base * 1.5;
    margin-right: $grid-gutter-width / 3;
  }
}

.requestAdditionalSeatsModal {
  :global(.modal-title *) {
    padding-bottom: 0px;
  }
  :global(.modal-footer) {
    justify-content: flex-start;
  }
}

.enrollLearnerFormWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  > :global(h3) {
    padding-bottom: 0px;
    padding-top: 5px;
  }
  > :global(button) {
    width: 41px;
    height: 41px;
    border: none !important;
    border-radius: $border-radius-lg;
    color: $white;
    outline-color: $red;
    background-color: $red;
    margin-right: $grid-gutter-width/3;
    > :global(svg) {
      margin-top: 2px;
    }
  }
  :global(button) {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    :global(svg) {
      align-self: center;
      font-size: 1.5rem !important;
    }
  }
}

.enrollLearnerForm {
  width: 100%;
  margin-top: $grid-gutter-width / 2;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1px;
  justify-content: flex-end;
  align-items: center;
  color: $black;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  > :global(*) {
    margin-bottom: $grid-gutter-width / 2 !important;
  }
  > :global(span) {
    margin-right: $grid-gutter-width/2;
  }
  > :global(.form-group) {
    width: 100%;
    @include media-breakpoint-up(md) {
      &:global(:nth-child(1)),
      &:global(:nth-child(2)),
      &:global(:nth-child(4)) {
        width: 20%;
      }
      &:global(:nth-child(3)) {
        width: 40%;
      }
    }
  }
  :global(label) {
    white-space: nowrap;
  }
  :global(input),
  :global(select) {
    // min-width: 50px;
    border: none !important;
    border-radius: 0px;
    outline-style: solid;
    outline-color: $gray-200;
    outline-offset: -4px;
    outline-width: 2px;
    outline-offset: -1px;
  }
  :global(button) {
    :global(svg) {
      position: relative;
      top: -2px;
      margin-left: 0.5rem;
      align-self: center;
      font-size: 1rem !important;
    }
  }
}

.exportAllButton {
  margin-bottom: $grid-gutter-width !important;
  max-width: 180px;
  :global(svg) {
    font-size: $font-size-base * 1.5;
    margin-right: $grid-gutter-width / 3;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
}
