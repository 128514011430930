@import '../../shared/styles/config';

.sidebar.sidebar {
  z-index: 2;
  button {
    :global(.is-tabbing) & {
      &:focus {
        outline: none !important;
        box-shadow: 0 0 0 0.2rem transparentize($red, 0.25) !important;
        border-radius: 5px !important;
      }
    }
  }
}

.heading.heading {
  display: block;
  font-size: $font-size-base * 1.25;
  font-weight: $font-weight-bold;
  color: $orange;
  margin-top: $grid-gutter-width / 2;
  margin-bottom: $grid-gutter-width / 2;
  &:first-child {
    margin-top: 0px;
  }
}

a.link.link,
button.link.link {
  color: $grey-darker !important;
  text-decoration: none;
  font-weight: $font-weight-base;
  text-align: left;
  &:hover,
  &:active,
  &:global(.button-active.button-active),
  &:global(.active.active) {
    background: none !important;
    box-shadow: inset 0px -3px 0px 0px $grey-darker !important;
  }
  &:active,
  &:global(.button-active),
  &:global(.active) {
    font-weight: $font-weight-bold !important;
  }
  :global(.is-tabbing) & {
    &:focus {
      outline: none !important;
      box-shadow: 0 0 0 0.2rem transparentize($red, 0.25) !important;
      border-radius: 5px !important;
    }
  }
}

.dropdown.dropdown {
  max-width: 300px;
}

.dropdownToggle.dropdownToggle {
  width: 100%;
  color: $grey-darker !important;
  text-align: left !important;
  margin-bottom: $grid-gutter-width / 3;
  padding-right: 1.5rem;
  border: 2px solid $table-border !important;
  border-radius: 0px !important;
  white-space: normal !important;
  &:after {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-100%);
    margin-left: auto !important;
    border-top-color: $grey-darker !important;
    border-bottom-color: $grey-darker !important;
  }
}

.dropdownMenu.dropdownMenu {
  width: 100%;
  padding: 0px !important;
  border: 2px solid $white !important;
  border-radius: 0px !important;
}

.dropdownMenuItem.dropdownMenuItem {
  display: block !important;
  padding: $grid-gutter-width / 2 !important;
  border: none !important;
  color: $grey-darker !important;
  white-space: normal !important;
  &:hover,
  &:active,
  &:focus,
  &:global(.active) {
    background-color: $pink !important;
    color: $red !important;
    box-shadow: none !important;
  }
}

.accordion.accordion {
  padding: 0px;
}

.card.card {
  border: none !important;
  margin-bottom: $grid-gutter-width / 3 !important;
  overflow: visible !important;
  &:last-child {
    margin-bottom: 0px !important;
  }
}

.cardHeader.cardHeader {
  display: block;
  background-color: transparent !important;
  padding: 0px !important;
  border: none !important;
  :global(button) {
    font-size: 1.2rem !important;
    font-weight: $font-weight-bold;
    text-align: left;
    color: $grey-darker;
    border: none !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    padding: 0px !important;
  }
  :global(button:after) {
    content: '';
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.15em;
    transform: scaleX(0.75) scaleY(1);
    transition: 0.3s transform ease-in-out;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  :global(button.active:after) {
    transform: scaleX(0.75) scaleY(-1);
  }
}

.cardBody.cardBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: $grid-gutter-width / 2 !important;
  padding-right: 0 !important;
}

@media print {
  .sidebar {
    display: none;
  }
}
