@import '../../shared/styles/config';

.alert {
  @include make-container();
  @include make-container-max-widths();
  width: 100%;
  padding: $grid-gutter-width;
  position: fixed;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  z-index: $zindex-tooltip;
  display: none;
}

.open {
  display: block;
}

.success {
  background-color: $success;
}
