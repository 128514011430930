@import '../../../shared/styles/config';

.snippetsContainer {
  padding-bottom: 150px + 2.5 * $grid-gutter-width;
}

.trialSnippetsContainer {
  padding-bottom: 150px + 2.5 * $grid-gutter-width;
  margin: 0 auto;
}

.titleContainer {
  margin-bottom: 15px;
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 15px;
    position: relative;

    h1 {
      padding-bottom: 0;
    }
  }
}

.iconContainer {
  width: 42px;
  padding: 10px;
  display: none;
  border-radius: 50%;
  background-color: $pink;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @include media-breakpoint-down(sm) {
    display: flex;
  }
}

.icon {
  width: 100%;
}

.title {
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

.dottedBorder {
  background-image: linear-gradient(to right, $dotted-border 20%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-size: 5px 1px;
  background-repeat: repeat-x;
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 1px;
  display: none;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.translations {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $grey-dark;
  border-bottom: 1px solid $grey-dark;
  padding-bottom: 20px;

  @include media-breakpoint-down(sm) {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
}
