.buttonRow {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  :global(.btn) {
    margin-top: 25px;
  }
  :global(.btn):not(:last-child) {
    margin-right: 25px;
  }
}

.centered {
  justify-content: center;
}
