@import '../../shared/styles/config';

.resource.resource {
  background-color: $purple-light;
  border-radius: 8px;
  margin-top: $grid-gutter-width;
  width: 100%;
  padding: $grid-gutter-width;
  text-transform: none;
  font-weight: 100;
  text-align: left;
  font-size: 18px;

  &:hover,
  &:focus {
    background-color: $purple-light;
    text-decoration: none;
  }

  p,
  span {
    line-height: 27px;
  }
}

.title {
  margin: 0;
  padding: 0;
  color: $orange;
}

.resourceTitle {
  font-weight: 600;
}

.content {
  color: $orange;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  font-size: $font-size-base * 2.5;
  margin-right: $grid-gutter-width;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  > p {
    margin-bottom: 0;
  }
}

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.55);
}

.modal {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  height: auto;
  width: auto;
  max-width: 100vw;
  padding: $grid-gutter-width;
}

.closeModal {
  position: absolute;
  background: none;
  border: none;
  right: 0;
  color: $white;
  transform: translateY(-100%);
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
