@import '../../shared/styles/config';

// Login form specific styles
.loginContainer {
  background-color: $grey;
  border: 1px solid $grey-form;
  margin: $grid-gutter-width * 2 0;
}

.loginContent {
  padding: 20px 10px;
  p {
    margin-bottom: 0;
  }
  :global(a:not(.btn.btn-primary)) {
    color: $red;
  }
}
