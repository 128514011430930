@import '../shared/styles/config';

.input {
  &:not(.radioInput) {
    width: 100%;
    padding: 10px;
  }
}

.radioInputGroup {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include media-breakpoint-down(xs) {
    justify-content: space-between;
  }
}
// double specificity to override bootstrap
.radioInput.radioInput:global(.form-check-inline) {
  margin-right: 1em;

  @include media-breakpoint-down(xs) {
    margin-bottom: 20px;
  }

  input[type='radio'] {
    /* Hide default browser style */
    appearance: none;
    border-radius: 50% !important;
    background-color: #ffffff;
    border: 1px solid $grey-form;
    cursor: pointer;
    height: 24px;
    width: 24px;
    position: relative;
    margin-bottom: 1px;
  }

  input[type='radio']::after {
    content: '';
    width: 0;
    height: 0;
    background-color: $orange;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  input[type='radio']:checked::after {
    width: 12px;
    height: 12px;
  }

  > :global(.invalid-feedback) {
    display: none !important; // Hide inline feedback, we provide a block level feedback for inline radio buttons
  }
}

.label {
  margin-bottom: 0;
  margin-right: 5px;
}

.errorMessage {
  color: $red-alt;
}

.errorAlertBox {
  background-color: $red-light;
  border: 1px solid $red;
  padding: $grid-gutter-width / 2;
  margin: $grid-gutter-width / 2 0;
}

.passwordStrength {
  padding: 5px;
  color: $grey-dark;
  background-color: $grey-light;
  display: inline-block;
  border: 1px solid $grey-form;
  > span {
    display: block;
    text-align: center;
    min-width: 200px;
  }
  &:global(.weak) {
    background-color: $warning;
    color: $green-darker;
  }
  &:global(.strong) {
    background-color: $success;
    color: $green-darker;
  }
}

.passwordStrengthMessage {
  font-size: $font-size * 0.725;
  margin-left: 5px;
}

// Override default Button styles for forms
.formButton {
  font-size: $font-size * 0.675 !important;
  margin: 24px 0 !important;
}

.showPasswordButton.showPasswordButton {
  background-color: $white;
  color: $red;
  padding: 6px;
  cursor: pointer;
  border-color: $input-border;
}

// Signup form specific styles
.signupContainer {
  background-color: $grey;
  padding: $grid-gutter-width * 2 0;
}

.smallLabel {
  font-size: 0.75 * $font-size;
}

.boldLabel {
  font-weight: 700;
  text-transform: uppercase;
}

.assessment {
  :global(.form-label) {
    margin-bottom: 0.5rem !important;
  }

  :global(.form-group) {
    margin-bottom: 4rem !important;
  }
}

.scaleContainer {
  position: sticky;
  top: 0;
  z-index: 1;
  color: $red;
  background: $grey;
}

.scale {
  width: 100vw;
  position: relative;
  left: 50%;
  margin-left: -50vw;
  background: $grey;
}

.scaleInner {
  font-size: 1rem;
  padding: 0.5 * $grid-gutter-width 0;
  color: $red;
  & > :last-child {
    margin-bottom: 0;
  }
}

textarea {
  resize: vertical;
  min-height: 50px;
  max-height: 250px;
}
