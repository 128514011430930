@import '../../shared/styles/config';

.container {
  @include fadeIn;
}

.summary {
  box-shadow: 0px 0px 13px -1px rgba(0, 0, 0, 0.25);
  padding: 25px;
  color: $red-alt;
  font-size: $font-size-base * 1.22;
  font-family: $font-family-serif;
  text-align: center;

  > :last-child {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: 20px;
}

.description {
  margin-bottom: 20px;
}
