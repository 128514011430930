@import '../../shared/styles/config';

.snippetOptions {
  background: $grey;
  padding-top: $grid-gutter-width * 0.5;
  padding-bottom: $grid-gutter-width;
}

.returnButtons {
  padding-bottom: 50px;
}
