@import '../../shared/styles/config';

.sidebarContainer {
  width: 100%;
  margin-bottom: $grid-gutter-width;
  height: 100%;
}

.sidebar {
  position: sticky;
  padding: $grid-gutter-width;
  min-height: 100px;
  box-shadow: 3px 0px 13px -1px rgba(0, 0, 0, 0.25);
}
