@import '../../shared/styles/config';

.programDetailsHeader {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.programStatus {
  margin-bottom: $grid-gutter-width / 2;
  padding: $grid-gutter-width / 2;
  background-color: $grey;
}

.programStatusTitle {
  font-weight: $font-weight-bold;
  margin-bottom: 4px;
}

.programStatusSubtitle {
  margin-bottom: 0;
}

.programCompletionTable {
  :global(th) {
    font-weight: normal;
  }
  @include media-breakpoint-up(md) {
    :global(td) {
      font-size: $font-size-sm;
    }
  }
}

.checkIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  font-size: 1.55rem;
}
