@import '../../../shared/styles/config';

.intro {
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width * 2;
  }
}

.backToProgramLink {
  display: block;
  margin-bottom: 20px;
}

.translations {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: $grey-dark;
  padding-bottom: 10px;
}
