@import '../../shared/styles/config';

.singleProgram {
  display: flex !important;
  margin-bottom: 14px !important;

  h4 {
    margin-bottom: 0;
  }
}

.icon {
  margin-right: 25px;
  max-width: 75px;
  width: 100%;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.programsContainer {
  align-self: flex-start;
  box-shadow: 3px 0px 13px -1px rgba(0, 0, 0, 0.25);
  padding: 25px !important;

  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    margin-right: 30px;
  }
}
