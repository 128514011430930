@import './shared/styles/config';
@import '~bootstrap/scss/bootstrap';
@import './shared/styles/global-styles';
@import './shared/styles/typography';
@import './shared/styles/browser-update';

body:not(.is-tabbing) {
  button,
  a,
  li {
    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: none !important;
    }
  }
  .btn {
    &:focus,
    &:focus-visible,
    &:focus-within {
      box-shadow: none !important;
    }
  }
}
.maintainance-notice {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  text-align: center;
  width: 100%;
  top: 0;
  z-index: 1000;
  p {
    margin: 0 auto;
    width: 75%;
  }
}
@media (max-width: 1439px) {
  .maintainance-notice p {
    font-size: 0.8rem;
  }
}

@media (min-width: 1440px) {
  .maintainance-notice p {
    font-size: 1rem;
  }
}
