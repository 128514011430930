@import '../../shared/styles/config';

.footer {
  background-image: $gradient;
  padding: $grid-gutter-width;
  margin-top: auto;

  @include media-breakpoint-up(md) {
    padding: $grid-gutter-width 0;
  }
}

.text {
  font-size: 12/18 * $font-size;
  color: $white;
  margin: 0;
}

@media print {
  .footer {
    display: none;
  }
}
