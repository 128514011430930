@import '../../shared/styles/config';

button.button,
a.button,
button.grow,
a.grow,
button.button,
a.primary {
  align-items: center;
  background: $button-colour;
  border-radius: 50px;
  border: none;
  color: $white;
  font-size: $button-font-size;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  padding: 0.875rem 2.1875rem;
  text-transform: uppercase;

  &:not(a) {
    display: flex;
  }

  &:hover,
  &:focus,
  &:active,
  &:global(.active) {
    background: transparentize($button-colour, 0.13);
    color: $white;
  }
}

button.button.sm {
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important;
}

button.grow,
a.grow {
  transition: all 0.3s ease !important;
  &:hover,
  &:focus {
    background: $grey-dark;
    color: $white;
    transform: scale(1.1);
    transition: all 0.3s ease !important;
  }
}

button.secondary,
a.secondary {
  background: $white;
  color: $button-colour;

  &:hover,
  &:focus,
  &:active,
  &:global(.active) {
    background: transparentize($white, 0.13) !important;
    color: $button-colour !important;
  }
}

button.outline,
a.outline {
  background: none !important;
  border-radius: 0 !important;
  padding: 0.5625rem 0 !important;
  text-transform: none !important;

  &:hover,
  &:focus,
  &:active,
  &:global(.active) {
    background: none !important;
    box-shadow: inset 0px -3px 0px 0px $white;
  }
}

button.link,
a.link {
  text-decoration: underline;
  background: none;
  border-radius: inherit;
  border: inherit;
  color: inherit;
  display: unset;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  text-transform: unset;
  vertical-align: unset;

  &:hover,
  &:focus,
  &:active,
  &:global(.active) {
    color: $link-hover !important;
    box-shadow: none;
    background: none !important;
    text-decoration: underline;
  }
}

button.unstyled,
a.unstyled {
  background: inherit !important;
  border-radius: inherit;
  border: inherit;
  box-shadow: none !important;
  color: inherit;
  display: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
  vertical-align: inherit;

  &:hover,
  &:focus,
  &:active,
  &:global(.active) {
    color: inherit;
    box-shadow: none !important;
    text-decoration: inherit !important;
  }
}

.buttonSpinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button:disabled {
  background: $grey-dark;
  &:hover,
  &:active,
  &:focus {
    transform: none;
    background: $grey-dark;
  }
}
