::selection {
  color: white;
  background-color: $primary;
}

body {
  font-weight: 400;
}

h1,
.h1 {
  font-family: $font-family-serif;
  color: $primary;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1;
  padding-bottom: 15px;
}

h2,
.h2 {
  font-family: $font-family-serif;
  color: $orange;
  font-size: 2rem;
}

h3,
.h3 {
  font-family: $font-family-sans-serif;
  color: $orange;
  font-size: 1.375rem;
  font-weight: 700;
  padding-bottom: 13px;
  margin-bottom: 0;
  text-transform: uppercase;
}

h4,
.h4 {
  font-family: $font-family-sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.2em;
  color: $grey-dark;
}

h5,
.h5 {
  font-family: $font-family-serif;
}

h6,
.h6 {
  font-family: $font-family-serif;
}

.uppercase {
  text-transform: uppercase;
}
