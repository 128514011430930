@import '../../shared/styles/config';

:global(.table thead th) {
  vertical-align: middle !important;
}
:global(.table td) {
  vertical-align: middle !important;
}

.inputAddon {
  background-color: $white !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0 !important;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.paginationText {
  margin-bottom: 0;
}

.paginationButton {
  margin-left: 12px;
  padding: 4px 10px !important;
}

.CSVButton {
  @include media-breakpoint-down(sm) {
    display: none !important;
  }
  padding: 4px 10px !important;
  background-color: $white !important;
  color: $primary !important;
  border-color: $white !important;
  font-size: 1.65rem !important;
}
