@import '../../shared/styles/config';

.modalOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.55);
}

.modal {
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  height: auto;
  width: 90vw;
  max-width: 480px;
  @include media-breakpoint-up(md) {
    width: auto;
  }
  background-color: $white;
  border-radius: 4px;
  padding: $grid-gutter-width;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modalClose {
  background: none;
  border: none;
}

.modalHeading {
  margin: 0;
  padding: 0;
  @include media-breakpoint-down(xs) {
    text-align: center;
    margin-left: 27px;
    width: 100%;
  }
}

.modalHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: $grid-gutter-width / 2;
}
