#buorg {
  background: $gradient;
  color: $white;
  font-family: $font-family-sans-serif;
  font-size: 1rem;
  animation: none;
  border: none;
  box-shadow: none;
  animation: none;
  .buorg-moremsg {
    font-size: .75rem;
  }
  b {
    font-weight: 600;
  }
  a {
    background: $white;
    border-radius: 50px;
    margin: 0;
    padding: 10px 25px 8px 25px;
    align-items: center;
    color: $red;
    font-size: $button-font-size;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    border: none;
    box-shadow: none;
    border: none;
  }
}
